<template>
  <div>
    Analytics
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { required, emailValidator, pinLength } from '@core/utils/validation'

// import axios from '@axios'
// import { useRouter } from '@core/utils'

export default {
  setup() {
    // Template Ref
    // const { router } = useRouter()

    // const getAdmins = () => {
    //   axios
    //     .post('/admins')
    //     .then(response => {
    //       console.log(response)
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // }

    return {
      validators: {
        required,
        emailValidator,
        pinLength,
      },
    }
  },
}
</script>
